import axios from "axios";
import { returnErrors } from "./messages";
import {
    USER_LOADED,
    USER_LOADING,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    FORGOT_PASS_SUCCESS,
    FORGOT_PASS_FAIL,
    SAVE_FORGOT_PASS,
    CHECK_VERIFICATION_CODE
} from './types';

import {API_URL} from "../../config";

// CHECK TOKEN AND LOAD USER
export const loadUser = () => (dispatch, getState) => {
    // USER LOADING
    dispatch({ type: USER_LOADING });
    axios
        .get(`${API_URL}/auth/user`, tokenConfig(getState))
        .then(res=>{
            localStorage.setItem("token", res.data.token);
            dispatch({type: USER_LOADED, payload: res.data});
        })
        .catch(err => {
            dispatch(returnErrors(err.response, err.error));
            dispatch({type: AUTH_ERROR})
        })
};
export const login = (username, password) => (dispatch) => {
    axios
        .post(`${API_URL}/auth/login`, { username: username, password: password })
        .then(res=>{
            localStorage.setItem("token", res.data.token);
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data
            });
        })
        .catch(err => {
            console.log("login error", err);
            //dispatch(returnErrors(err.response, err.error));
            dispatch({
                type: LOGIN_FAIL,
                payload: err
            })
        })
};
export const logout = () => (dispatch, getState) => {
    axios
        .post(`${API_URL}/auth/logout`, null, tokenConfig(getState))
        .then(res=>{
            dispatch({type: LOGOUT_SUCCESS});
        })
        .catch(err => {
            console.log("logout error", err.error);
        })
};

export const forgotPass = (username) => (dispatch) => {
    axios
        .post(`${API_URL}/auth/forgotPass`, { username: username})
        .then(res=>{
            dispatch({
                type: FORGOT_PASS_SUCCESS,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch(returnErrors(err.response, err.error));
            dispatch({
                type: FORGOT_PASS_FAIL
            })
        })
};
export const saveForgotPass = ( username, verificationCode, password, passwordConfirm ) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${API_URL}/auth/saveForgotPass`, { username, verificationCode, password, passwordConfirm }, tokenConfig(getState));
        dispatch ({
            type: SAVE_FORGOT_PASS,
            payload: res.data
        });
    }
    catch (e) {
        console.log("response", e.response);
        console.log("error", e.error);
        console.log("e", e);
    }
};
export const checkVerificationCode = ( verificationCode, username ) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${API_URL}/auth/checkVerificationCode`, { verificationCode:verificationCode, username:username }, tokenConfig(getState));
        dispatch ({
            type: CHECK_VERIFICATION_CODE,
            payload: res.data
        });
    }
    catch (e) {
        console.log("response", e.response);
        console.log("error", e.error);
        console.log("e", e);
    }
};

export const tokenConfig = (getState) => {
    // GET TOKEN FROM STATE
    const token = getState().auth.token;

    // HEADERS
    const config = {
        withCredentials: true,
        headers: {
            "Content-Type":"application/json"
        }
    };

    // IF TOKEN ADD TO HEADERS
    if (token) {
        config.headers['Authorization']= token;
    }
    return config;
};
