import { Link } from "react-router-dom";
import React from "react";
import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import {deleteUser, tokenConfig} from "../../../client/actions/apiActions";
import {toastr} from "react-redux-toastr";
import axios from "axios";
import {API_URL} from "../../../config";

const TabBusinessesClientUsers = (props) => {
  const { userRole, userInfo, user, setSuccessMessage } = props;

  const resendInvite = async (userId) => {
      const res = await axios.get(
          `${API_URL}/resendInvite/${userId}`,
          tokenConfig(),
      );
      if (res.data.pageName === "resendInvite") {
          toastr.success("Email Sent", `Email been resent!`);
      }
  };

  const deleteUser = (userId, userName) => {
    const deleteOptions = {
      title: "Confirm Delete",
      message: `Are you sure you wish to delete this user "${userName}"`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            props.deleteUser(userId);
            setSuccessMessage("User has been deleted!");
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    };
    confirmAlert(deleteOptions);
  };

  return (
    <tr>
      <td nowrap="true" className="align-middle" style={{fontSize: "12px"}}>
        {user.name} &nbsp;
      </td>
      <td nowrap="true" className="align-middle" style={{fontSize: "12px"}}>
        {user.phone} &nbsp;
      </td>
      <td nowrap="true" className="align-middle" style={{fontSize: "12px"}}>
        {user.email} &nbsp;
      </td>
      {user.accountLocked === "1" && (
        <td nowrap="true" className="align-middle text-center">
          <i className="fas fa-check-circle text-danger" />
        </td>
      )}
      {user.accountLocked !== "1" && (
        <td nowrap="true" className="align-middle text-center">
          <i className="fas fa-times-circle text-secondary" />
        </td>
      )}
      <td nowrap="true" className="text-right">
        {user.password === 0 && (
          <button
            className="btn btn-primary btn-sm m-1"
            onClick={() => resendInvite(user.id)}
            title="Resend Invite Email"
          >
            <i className="fas fa-envelope" />
          </button>
        )}

        {(userRole.usersCanEdit === "1" || userInfo.userType === "A") &&
          userRole.roleType === "1" && (
            <Link
              className="btn btn-primary btn-sm m-1"
              to={"/clients/user_association/" + user.id}
              title="Edit User Associations"
            >
              <i className="fas fa-users" />
            </Link>
          )}

        {(userRole.usersCanEdit === "1" || userInfo.userType === "A") && (
          <Link
            className="btn btn-warning btn-sm m-1"
            to={"/clients/user_edit/" + user.id}
            title="Edit User Information"
          >
            <i className="fas fa-pencil-alt" />
          </Link>
        )}

        {(userRole.usersCanDelete === "1" || userInfo.userType === "A") && (
          <button
            className="btn btn-danger btn-sm m-1"
            onClick={() => deleteUser(user.id, user.name + " " + user.email)}
            title="Delete User"
          >
            <i className="fas fa-times" />
          </button>
        )}
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => ({
  userRole: state.pages.clientList.userRole,
  userInfo: state.pages.clientList.user,
  data: state.pages.data,
});

export default connect(mapStateToProps, {
  deleteUser,
})(TabBusinessesClientUsers);
