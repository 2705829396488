import { Link } from "react-router-dom";
import UserRow from "./TabUsersUser";
import React from "react";
import { confirmAlert } from "react-confirm-alert";
import axios from "axios";
import {API_URL} from "../../../config";
import {tokenConfig} from "../../../client/actions/apiActions";
import {toastr} from "react-redux-toastr";

const UsersUserList = (props) => {
  const { client, setSuccessMessage } = props;

  const resendInvite = async (userId) => {
    const res = await axios.get(
        `${API_URL}/resendInvite/${userId}`,
        tokenConfig(),
    );
    if (res.data.pageName === "resendInvite") {
      toastr.success("Email Sent", `Email been resent!`);
    }
  };

  const deleteUser = (userId, userName) => {
    const deleteOptions = {
      title: "Confirm Delete",
      message: `Are you sure you wish to delete this user "${userName}"`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            props.deleteUser(userId);
            setSuccessMessage("User has been deleted!");
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    };
    confirmAlert(deleteOptions);
  };

  return (
    <>
      <tr>
        <td nowrap="true" className="align-middle" style={{fontSize: "12px"}}>
          {client.name}
        </td>
        <td nowrap="true" className="align-middle" style={{fontSize: "12px"}}>
          {client.phone}
        </td>
        <td nowrap="true" className="align-middle" style={{fontSize: "12px"}}>
          {client.email}
        </td>
        {client.accountLocked === "1" && (
          <td nowrap="true" className="align-middle text-center">
            <i className="fas fa-check-circle text-danger" />
          </td>
        )}
        {client.accountLocked !== "1" && (
          <td nowrap="true" className="align-middle text-center">
            <i className="fas fa-times-circle text-secondary" />
          </td>
        )}
        <td nowrap="true" className="text-right">
          {client.password === 0 && (
            <button
              className="btn btn-primary btn-sm m-1"
              onClick={() => resendInvite(client.id)}
              title="Resend Invite Email"
            >
              <i className="fas fa-envelope" />
            </button>
          )}
          <Link
            className="btn btn-primary btn-sm m-1"
            to={"/clients/user_association/" + client.id}
          >
            <i className="fas fa-users" />
          </Link>
          {client.type === "Company" && (
            <Link
              className="btn btn-warning btn-sm m-1"
              to={"/clients/client_edit/" + client.id}
            >
              <i className="fas fa-pencil-alt" />
            </Link>
          )}
          {client.type === "User" && (
            <Link
              className="btn btn-warning btn-sm m-1"
              to={"/clients/user_edit/" + client.id}
            >
              <i className="fas fa-pencil-alt" />
            </Link>
          )}

          <button
            className="btn btn-danger btn-sm m-1"
            onClick={() => deleteUser(client.id, client.name)}
          >
            <i className="fas fa-times" />
          </button>
        </td>
      </tr>

      {typeof client.associatedUsers !== "undefined" && (
        <>
          {client.associatedUsers.map((user) => (
            <UserRow user={user} key={`user-${user.id}`} />
          ))}
        </>
      )}
    </>
  );
};
export default UsersUserList;
